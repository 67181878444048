import React from 'react'
import Volunteer from '../components/volunteer/Volunteer'

const VolunteerPage = () => {
  return (
    <>
        <Volunteer/>
    </>
  )
}

export default VolunteerPage